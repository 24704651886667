@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'react-medium-image-zoom/dist/styles.css';
@import '../node_modules/devicon/devicon.min.css';

@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.skills{
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  color:rgb(249, 249, 249);
  text-shadow: 1px 1.5px #1b1c1c;
}

#body_skills{
  background-color: rgba(41, 46, 70, 0.632);
}

#bodyColor{
  background-color: #010b1d;
}

#navOverlay {
  position: fixed;
  width:100%;
  height: 100%;
  z-index: 400;
  background-color: rgba(0, 0, 0, 0.762);
}

#mobileNavBody {
  z-index: 950;
}


.icons>div {
  text-align: center;
  display:flex;
  justify-content: center;
  flex-direction: column;
  min-height: 60px;
}

@media (min-width: 768px) {
  .icons>div {
    min-height: 90px;
  }
}

@media (min-width: 1024px) {
  .sizing{
    font-size: 7rem;
  }
}

@media (min-width: 1280px) {
  .sizing{
    font-size: 9rem;
  }
}

.icons i {
  display: inline-block;
  cursor: pointer;
}


#mocha{
  color:rgb(143, 75, 50);
}

#postgres{
  color:rgb(34, 135, 172)
}

#mysql{
  color:rgb(31, 121, 199);
}


#xd{
color:rgb(210, 46, 109);
}

#home_page{
  margin-top: 56px;
}

#about_me{
  background-color:white;
  height: 90%;
  width: 100%;
}


#aboutMe_body{
 color: #112f5c;
}

#aboutMe_header{
  font-family: 'Inter', sans-serif;
  color: #06204a;
}

.bgTimeline{
  background-color:rgb(232, 34, 110);
}

.timelineTXT{
  background-color: rgba(41, 46, 70, 0.632);
  border-color:rgb(232, 34, 110);

}

.descOfProj{
  color:rgb(72, 187, 222);
  font-family: 'M PLUS Rounded 1c', sans-serif;
}

.githubBtn2{
  color:rgb(245, 41, 119);
}

.githubBtn2:hover{
  color:rgb(197, 33, 96);;
  cursor: pointer
}
.navHover:hover{
  color:rgb(148, 204, 233);
}
#navbar{
  background-color:#050813d2
}
#mobileNavBody{
  background-color:#060a17f1;
  box-shadow: -6px -6px 8px rgb(0, 0, 0) ;
}

.mobileMenu:hover{
background-color:#333f52f3;
border-color: rgb(121, 177, 205);
}


.githubBtn{
  color:rgb(148, 204, 233);
}
.githubBtn:hover{
  color:rgb(77, 149, 185);
  cursor: pointer
}

#projectsBtn{
  padding-right: 68%;
}

.aboutProjects{
color:#6b778c;
}


#tsparticles{
  width: 100%;
  height: 100%;
}

#menuBtn{
  text-shadow: 2px 2px #227791;
  background-color:#060a17de;
  z-index: 850;
}

#textAnimation{
  text-shadow: 2px 2px #0d0e17cb;
}

#secondAnimation{
  font-family: 'IBM Plex Mono', monospace;
}

#certificate:hover{
  box-shadow: 0 0 6px #043157;
}
.indent {
  text-indent:1em;
}

article p {
  padding-bottom:20px;
}

#contact_me_button{
  background-color: rgb(214, 32, 102);

}

#contact_me_button:hover{
  background-color: rgb(187, 31, 91);
}

#contactMe{
  border-color: rgb(228, 36, 109);
}
#contactMe:hover{
  border-color: rgb(168, 26, 81);
}
